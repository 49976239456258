.ant-drawer .ant-drawer-title {
    display: flex;
    justify-content: center;
    color: #000;
    font-family: Dosis;
    font-size: 25px !important;
    padding: 0 5px;
}

#btn-secondary{
    padding: 10px 34px !important;
    background-color: #03b9ac;
    user-select: none;
    line-height: 1.5;
    font-size: 14px;
    color: #fff;
    border-color: #03b9ac;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    text-decoration: none;
    box-sizing: border-box;
    height: 58px;
    border-radius: 0.25rem;
    font-weight: bold;
}

#btn-secondary:hover{
    border-color: #029389;
    background-color: #029389;
    color: #fff !important;
}

.ant-select .ant-select-arrow{
    position: absolute;
    top: 50%;

}

.ant-select-single.ant-select-sm .ant-select-selector{
    align-items: "center"
}