.ant-btn-primary {
    background-color: #03b9ac;
    user-select: none;
    line-height: 1.5;
    font-size: 24px;
    padding: 10px 54px !important;
    color: #fff;
    border-color: #03b9ac;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    text-decoration: none;
    box-sizing: border-box;
    height: auto;
    border-radius: 0.25rem;
    font-weight: bold;
}

.ant-btn-primary:hover {
    background-color: #029389;
}

.ant-form-item-control-input {
    display: flex;
    
}

.ant-form-item-control-input-content {
    display: flex;
}

.ant-input {
    background-color: #fff;
    border: 1px solid #dcdfe6;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    height: 58px;
    line-height: 40px;
    outline: 0;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    margin: auto;
}

.ant-input:hover  {
    box-shadow: none;
}

.ant-input:focus {
    border-color: #c0c4cc !important;
    box-shadow: none;
    
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #c0c4cc !important;
    box-shadow: none;
}

.ant-input-affix-wrapper:focus .ant-input-affix-wrapper-focused {
    border-color: #c0c4cc !important;
    box-shadow: none;
    
}

.ant-form-item-label-left .ant-form-item-required {
    font-weight: 600;
    color: #606266 !important;
    line-height: 40px;
    padding: 0 12px 0 0;
    font-size: 20px;
    margin-top: 20px;
    vertical-align: middle;
    margin-bottom: 0;
    font-family: Dosis !important;
    font-weight: bold;
    
}

.ant-input-affix-wrapper-lg {
    border-radius: 4px;
}

.ant-form-item-explain-error {
    padding-top: 10px;
}

.ant-form-item-margin-offset {
    margin: 0px !important;
}

.ant-checkbox {
    top: 0.1em !important
}