@import "~bootstrap/scss/bootstrap";

.nav-link {
  color: #03b9ac !important;
}

.nav-link:hover {
  color: #029389 !important;
}

#nav-link-footer {
  color: #fff !important;
}

#nav-link-footer:hover {
  color: #fff !important;
}

.header-bg-shape-1 {
  width: 100%;
  height: 700px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 0 100%);
  background: linear-gradient(232.69deg, #4E7A99 28.37%, #69B2A8 95.18%);
  z-index: 1;
}

.header-bg-shape-2 {
  width: 100%;
  height: 700px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 80%);
  background-color: #69B2A8;
  opacity: 0.3;
  z-index: 0;
}

.accordion-button{
  font-family: "Poppins";
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  border: none !important;
  font-weight: 600;
  color: #000000 !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 52px;
}

#asc-desc-icons {
  color: #c5c5c5
}

#asc-desc-icons:hover {
  color: #029389
}

.ant-pagination .ant-pagination-item {
  font-family: "Dosis" !important;
}

#button-small-outline {
  padding: 10px 34px !important;
  background-color: #fff;
  user-select: none;
  line-height: 17px;
  font-size: 14px;
  color: #03b9ac;
  border-color: #03b9ac;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  text-decoration: none;
  box-sizing: border-box;
  height: 38px;
  border-radius: 0.25rem;
  font-weight: bold;
}

#button-small-outline-red {
  padding: 10px 34px !important;
  background-color: #fff;
  user-select: none;
  line-height: 17px;
  font-size: 14px;
  color: red;
  border-color: red;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  text-decoration: none;
  box-sizing: border-box;
  height: 38px;
  border-radius: 0.25rem;
  font-weight: bold;
}

#button-small-outline:hover{
  border-color: #029389;
  background-color: #029389;
  color: #fff !important;
}

#button-small-outline-green-bg {
  padding: 10px 34px !important;
  background-color: #03b9ac;
  user-select: none;
  line-height: 17px;
  font-size: 14px;
  color: #fff;
  border-color: #03b9ac;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  text-decoration: none;
  box-sizing: border-box;
  height: 38px;
  border-radius: 0.25rem;
  font-weight: bold;
}

#button-small-outline-green-bg:hover{
  border-color: #03b9ac;
  background-color: #fff;
  color: #03b9ac !important;
}

.button-small-compact{
  font-weight: bold;
  background-color: #03b9ac;
  color: #fff;
  border-color: #03b9ac !important;
}

.button-small-compact:hover{
  font-weight: bold;
  border-color: #03b9ac !important;
  background-color: #fff;
  color: #03b9ac !important;
}

.ant-pagination-options-size-changer {
  height: 38px;
}

.ant-input-number-lg input.ant-input-number-input{
  height: 50px;
}

.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover{
  border-color: #03b9ac !important;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn-sm{
  background-color: transparent !important;
}

.ant-radio-button-wrapper:not(:first-child)::before{
  width: 0px;
}
