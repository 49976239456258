.ant-layout-sider-trigger {
    background-color: #029389 !important;
}

.ant-layout .ant-layout-sider-trigger{
    position: absolute;
}

.ant-menu-light .ant-menu-item-selected{
    color: #029389;
}

.ant-pagination .ant-pagination-item-active {
    border-color: #029389;
    height: 38px;
}

.ant-pagination .ant-pagination-item {
    height: 38px;
}

.ant-pagination .ant-pagination-next{
    height: 38px;
}

.ant-pagination .ant-pagination-prev{
    height: 38px;
}

.ant-pagination .ant-pagination-jump-next{
    height: 38px;
    line-height: 38px;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
    background: #029389;
    color: white;
}

.hidden {
    display: none !important;
}

.ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover>td {
    background: none;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected, :where(.css-dev-only-do-not-override-w8mnev).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, :where(.css-dev-only-do-not-override-w8mnev).ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected, :where(.css-dev-only-do-not-override-w8mnev).ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected{
    background-color: white;
    color: #029389;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after{
    border-bottom: none;
}

@media(max-width: 576px) { 
    .ant-menu-title-content{
        display: none;
    };
    .ant-form-item .ant-form-item-label >label{
        font-size: 15px !important;
        height: auto !important;
    };
    .ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
        flex: auto !important;
    }
}

.adm-tab-bar-item-active{
    color: white !important;
    background-color:#03b9ac;
}

.adm-popup-body{
    border-radius: 16px 16px 0px 0px
}
