.ant-drawer .ant-drawer-title {
    display: flex;
    justify-content: center;
    color: #000;
    font-family: Dosis;
    font-size: 25px !important;
    padding: 0 5px;
}

#btn-secondary{
    padding: 10px 34px !important;
    background-color: #03b9ac;
    user-select: none;
    line-height: 1.5;
    font-size: 14px;
    color: #fff;
    border-color: #03b9ac;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    text-decoration: none;
    box-sizing: border-box;
    height: 58px;
    border-radius: 0.25rem;
    font-weight: bold;
}

#btn-secondary:hover{
    border-color: #029389;
    background-color: #029389;
    color: #fff !important;
}

#btn-secondary-outline{
    padding: 10px 34px !important;
    background-color: #fff;
    user-select: none;
    line-height: 1.5;
    font-size: 14px;
    color: #03b9ac;
    border-color: #03b9ac;
    text-align: center;
    vertical-align: middle;
    border: 1px solid;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    text-decoration: none;
    box-sizing: border-box;
    height: 58px;
    border-radius: 0.25rem;
    font-weight: bold;
}

#btn-secondary-outline:hover{
    border-color: #029389;
    background-color: #029389;
    color: #fff !important;
}

#btn-secondary-outline-white{
    padding: 10px 34px !important;
    background-color: #fff;
    user-select: none;
    line-height: 1.5;
    font-size: 14px;
    color: #029389;
    border-color: #fff !important;
    text-align: center;
    vertical-align: middle;
    border: 1px solid;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    text-decoration: none;
    box-sizing: border-box;
    height: 58px;
    border-radius: 0.25rem;
}

#btn-secondary-outline-white:hover{
    border-color: #fff;
    background-color: transparent;
    color: #fff !important;
}

#btn-secondary-outline-dashed{
    padding: 10px 34px !important;
    background-color: transparent;
    user-select: none;
    line-height: 1.5;
    font-size: 14px;
    color: #fff;
    border-color: #fff !important;
    text-align: center;
    vertical-align: middle;
    border: 1px dashed;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    text-decoration: none;
    box-sizing: border-box;
    height: 58px;
    border-radius: 0.25rem;
}

#btn-secondary-outline-dashed:hover{
    border-color: #029389;
    background-color: #fff;
    color: #029389 !important;
}

.ant-btn.ant-btn-sm{
    height: auto;
    width: auto;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.ant-btn.ant-btn-sm:hover{
    background-color: #029389;
    color:#fff !important;
    border-color: #029389;
}

.ant-tour-buttons{
    display: flex;
}

@media(max-width: 576px) { 
    .ant-picker-panels { 
      flex-direction: column;
      width: 96%;
    } 
  }