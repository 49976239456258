.ant-input-number .ant-input-number-input {
    height: 50px;
    padding: 2px;
    font-family: "Dosis";
}

.ant-form-vertical .ant-form-item .ant-form-item-control-input{
    min-height: 0px;
}

.ant-select-item-option-content{
    display: flex;
    flex-wrap: wrap;
    height: 58px;
    font-size: 18px;
    font-family: "Dosis";
    align-content: center;
}

.ant-picker-input >input{
    font-family: "Dosis";
    font-size: 18px !important;
}

.ant-input:hover{
    border-color: #03b9ac;
}

.ant-input:focus{
    border-color: #03b9ac;
    box-shadow: none;
}

.ant-form-item-label>label{
    height: 48px !important;
    vertical-align: text-top;
}

.ant-select-selection-item{
    display: flex;
    flex-wrap: wrap;
    font-family: "Dosis";
    font-size: 18px !important;
    align-content: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 100%;
    min-width: 120px;
}

.ant-form-item{
    padding: 10 0px;
}

.ant-form-item-control-input-content{
    display: flex;
    justify-content: space-between;

}

.ant-select-multiple .ant-select-selector {
    height: 38px;
}

.ant-pagination .ant-pagination-item{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-form-item .ant-form-item-control-input-content .ant-pagination .ant-pagination-options-quick-jumper input{
    height: 38px;
}

.ant-btn.ant-btn-block {
    height: 58px;
}

.ant-form-item-required {
    font-weight: 600 ;
    color: #606266 !important;
    font-size: 20px !important;
    vertical-align: middle;
    margin-bottom: 0;
    font-family: Dosis !important;
    font-weight: bold;
}

.ant-form-item-label>label {
    font-weight: 600 ;
    color: #606266 !important;
    font-size: 20px !important;
    vertical-align: middle;
    margin-bottom: 0;
    font-family: Dosis !important;
    font-weight: bold;
}

.ant-steps-item-title{
    font-weight: 400 ;
    color: #606266 !important;
    font-size: 18px !important;
    vertical-align: middle;
    margin-bottom: 0;
    font-family: Dosis !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 52px;
}

.ant-select-multiple .ant-select-selection-item{
    height: 48px;
}

.ant-select-multiple .ant-select-selection-item-content .ant-input .ant-select-dropdown .ant-select-item .ant-picker .ant-picker-input >input .ant-input-number .ant-input-number-input .ant-steps.ant-steps-small .ant-steps-item-title{
    display: flex;
    align-items: center;
    font-family: "Dosis";
    font-size: 20px;
}

.ant-input-number{
    padding: 4px 11px;
    height: 58px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #03b9ac;
    border-color: #03b9ac;
}

.ant-steps-item-icon:hover .ant-pagination .ant-pagination-item-active:hover {
    border-color: #03b9ac;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon{
    background-color: #03b9ac;
    border-color: #03b9ac;
    color:  #fff;
}
.ant-steps .ant-steps-item-icon .ant-steps-icon{
    color: #fff;

}
.ant-steps-icon:hover{
    color: #fff;
    border-color: #03b9ac;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after{
    background-color: #03b9ac;
}

.ant-switch-checked {
    background-color: #03b9ac !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view:is(.ant-picker-dropdown .ant-picker-cell-selected) .ant-picker-cell-inner .ant-picker-dropdown .ant-picker-cell-in-view:is(.ant-picker-dropdown .ant-picker-cell-range-start) .ant-picker-cell-inner .ant-picker-dropdown .ant-picker-cell-in-view:is(.ant-picker-dropdown .ant-picker-cell-range-end) .ant-picker-cell-inner{
    background-color: #03b9ac !important;
}

.ant-picker-today-btn{
    color:#03b9ac !important;
}

.ant-picker-cell-inner::before{
    border-color: #03b9ac !important;
}

.ant-select-selector:hover{
    border-color: #03b9ac !important;
}

.ant-picker:hover{
    border-color: #03b9ac !important;
}

.ant-picker-focused{
    border-color: #03b9ac !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon{
    color: #fff;
}

.ant-steps-item-wait:hover{
    color: #03b9ac !important;
    border-color: #03b9ac !important;
}

.ant-steps-item-container:hover {
    color: #03b9ac !important;
    border-color: #03b9ac !important;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
    border-color: #03b9ac ;
}

.resumeDiv {
    width: 100%;
    border-radius: 4px;
    border: 1px solid;
    padding: 10px;
    margin-bottom: 15px;
    font-family: "Dosis";
    border-color: #03b9ac;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title .ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle .ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon .ant-picker-dropdown .ant-picker-today-btn .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon .ant-pagination .ant-pagination-item-active:hover a{
    color: #03b9ac;
}

.ant-picker-focused .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector .ant-input:focus .ant-input-focused .ant-input:hover{
    border-color: #03b9ac ;
    box-shadow: none;
}

.ant-picker-dropdown .ant-picker-cell-in-view:is.ant-picker-dropdown .ant-picker-cell-selected .ant-picker-cell-inner .ant-picker-dropdown .ant-picker-cell-in-view:is.ant-picker-dropdown .ant-picker-cell-range-start .ant-picker-cell-inner .ant-picker-dropdown .ant-picker-cell-in-view:is.ant-picker-dropdown .ant-picker-cell-range-end .ant-picker-cell-inner .ant-switch.ant-switch-checked .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
    background-color: #03b9ac;
}

.ant-picker-dropdown .ant-picker-cell-in-view:is.ant-picker-dropdown .ant-picker-cell-today .ant-picker-cell-inner::before{
    border: 1px solid #03b9ac;

}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    background-color: #03b9ac;
    border-color: #03b9ac ;

}

.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #03b9ac ;
}

.ant-btn-dashed:not(:disabled):hover{
    border-color: #03b9ac ;
    color: #03b9ac;
}

.ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon{
    color: #029389 !important;
    
}

.ant-form-item .ant-form-item-control-input-content{
    gap: 12px;
}

.ant-form label{
    font-size: 14px;
    font-family: Dosis;
}

.ant-input-disabled {
    background-color: #fff !important;
    color: #000000 !important;
}

.ant-form-item-label{
    height: "auto" !important;
}